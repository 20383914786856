.total-div {
  background-color: #40668c;
  border-radius: 2px 2px 2px 2px;
  color: #fff;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  font-size: 10px;
  font-weight: 700;
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 20px;
  padding: 3px 8px;
  position: relative;
  right: 30px;
  width: 90px;
  height: 60px;
}

.total-count {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 800;
  justify-content: center;
}

.MuiIconButton-root {
  padding: 8px;
  margin-left: 8px;
}

.MuiIconButton-root:hover {
  background-color: #f1f1f1;
}

.MuiButtonBase-root {
  font-size: smaller;
}

.MuiMenuItem-root {
  font-size: x-small !important;
}

.MuiMenuItem-gutters {
  font-size: smaller;
}

.css-1tzeee1 {
  font-size: x-small !important;
  max-width: 100px;
}

.css-1smcfvf-MuiButtonBase-root-MuiMenuItem-root {
  font-size: x-small !important;
  width: 150px !important;
}

.MuiInputBase-input,
.MuiInput-input,
.MuiInputBase-inputAdornedEnd {
  font-size: 11px !important;
}

.MuiTableHead-root .MuiTableCell-root {
  text-overflow: unset !important;
  white-space: normal !important;
  overflow: visible !important;
  word-wrap: break-word !important;
}

.mrt-table .MuiTableHead-root .MuiTableCell-root {
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  max-width: 200px;
}

.MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .css-1umw9bq-MuiSvgIcon-root {
  overflow: unset;
}

.Mui-TableHeadCell-Content-Wrapper.MuiBox-root {
  overflow: unset;
}

.Mui-TableHeadCell-Content-Labels.MuiBox-root {
  overflow: unset;
}

.Mui-TableHeadCell-Content.MuiBox-root {
  overflow: unset;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-animated {
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.MuiChip-label.MuiChip-labelMedium {
  font-size: 11px;
}

.Mui-TableHeadCell-Content.MuiBox-root {
  display: 'flex';
  justify-Content: 'center';
  align-Items: 'center';
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  margin-left: 9px;
}

.MuiButtonBase-root.MuiPaginationItem-root {
  align-items: center;
  border-radius: 2px;
  display: flex;
  font-size: 10px;
  font-weight: 700;
  height: 24px;
  justify-content: center;
  min-width: 24px;
}

ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  display: inline-block;
  height: auto;
  margin-inline-end: 8px;
  padding-inline: 7px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.2s;
  text-align: start;
  position: relative;
}

.MuiTableHead-root .MuiTableSortLabel-root {
  margin-right: 5.5px; 
}