@import "../../scss/variables.scss";

.menu {
  padding: 0 !important;

  li {
    padding: 0 !important;
    &:hover {
      color: #fff;
      background-color: $blue-01;
    }
  }

  .link {
    color: $black;
    font-size: 16px;
    font-weight: 700;
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    text-decoration: none;
    margin-right: 0;
  }
  .submenu {
    color: $black;
    font-size: 16px;
    font-weight: 700;
    height: 30px;
    width: 100%;
    align-items: center;
    text-decoration: none;
    padding: 0 20px;
    margin-right: 0;
    &:hover {
      color: #fff;
      background-color: $blue-01;
    }
    a {
      margin-right: 0;
    }
  }
}

.icon-menu {
  height: 40px;
  width: 40px;
  background-color: #fff;
  mask-image: url("../../assets/icons/sidemenu.svg");
  mask-size: cover;
}

.ant-dropdown-menu-item-icon {
  text-decoration: none !important;
  background-color: red;
}

.btn-menu,
.btn-menu:hover,
.btn-menu:focus {
  height: 40px !important;
  width: 40px !important;
  padding: 0 !important;
  color: #fff;
  background-color: transparent !important;
  border: none !important;
}

.menu-item {
  min-width: 90px;
  height: 30px;
  font-size: 12px;
  padding: 0;
  list-style: none;
  background-color: #fff;

  > span {
    height: 100%;
  }
}

.link {
  color: $black;
  font-size: 30px;
  font-weight: 700;
  height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  text-decoration: none;
  margin-right: 0;

  a {
    margin-right: 0;
  }

  &:hover {
    color: #fff;
    background-color: $blue-01;
  }
}

.submenu-heading {
  color: $black;
  font-size: 30px;
  font-weight: 700;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  text-decoration: none;
  margin-right: 0;

  a {
    margin-right: 0;
  }

  &:hover {
    color: #fff !important;
    background-color: $blue-01 !important;
  }
}
