.table-filter-wrapper{
    align-items: flex-end;
    background: linear-gradient(92deg,hsla(30,33%,54%,.62),#84c3e6 100%);
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    min-height: 60px;
    padding: 10px 10px;
    .form {
        display: flex;
        gap: 10px;
    }
}