.breadcrumb-wrapper {
  margin-top: 10px;
  margin-bottom: 14px;
  .ant-breadcrumb-link {
    cursor: pointer;
  }
}

.ant-breadcrumb {
  padding: 5px !important;
}
