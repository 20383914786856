.vendor-wrapper {
  display: flex;
  gap: 0 1rem;
  justify-content: space-between;

  .ant-table-wrapper {
    max-height: 80vh;
    overflow: auto;
    border: 1px solid gray;
    width: 100%;
  }

  .ant-card-bordered {
    border: 1px solid gray;
  }
}

.mapping-list {
  border: 1px solid gray;

  .ant-card-body {
    padding: 0;
  }

  .ant-card-head {
    border-bottom: 1px solid rgba(64, 102, 140, 0.3019607843);
    min-height: auto;
    padding-left: 14px;

    .ant-card-head-title {
      padding: 10px 0;
      font-size: 11px;
    }
  }

  ul {
    padding-left: 0;
    max-height: 60vh;
    overflow: auto;

    li {
      cursor: pointer;
      list-style: none;
      padding: 7px 0 7px 14px;
      font-size: 11px;
      border-bottom: 1px solid #f0f0f0;
    }

    li:hover {
      background: #e5f2f8;
    }
  }
}

.selected-background {
  background: #e5f2f8;
}

.upc-code-label {
  color: #5377b0 !important;
  font-weight: 700;
  font-size: 11px;
}

.ant-checkbox + span {
  font-weight: 700;
  font-size: 11px;
  color: #1b61a7;
}

.ant-descriptions-item-label {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #1b61a7 !important;
}

.fixed-button {
  position: relative;
  top: 5px;
  right: 1px;
  margin-right: 10px;
  width: 150px;

  //border: 0px solid #d6d6d6;
  //wrest of your styles
}

.quick-location-button {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 10px;
}

.quick-office-button {
  position: relative;
  top: -28px;
  left: 357px;
}

.input-field-lable {
  margin-top: -20px;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
