@import 'variables';

.toast-container {
  &.ant-notification-notice {
    border-left: 7px solid $green;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 8px 40px 8px 12px;

    &.success {
      border-color: $green;
    }

    &.error {
      border-color: $red;
    }

    &.warning {
      border-color: $orange;
    }

    .ant-notification-notice-with-icon {
      .ant-notification-notice-icon {
        top: calc(50% - 12px);
      }

      .ant-notification-notice-icon-success {
        color: $green;
      }

      .ant-notification-notice-icon-error {
        color: $red;
      }

      .ant-notification-notice-message {
        font-size: 14px;
        font-weight: 700;
      }

      .ant-notification-notice-description {
        font-size: 12px;
        color: $gray-00;
      }
    }

    .ant-notification-notice-close {
      top: calc(50% - 10px);
    }
  }
}
