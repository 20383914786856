.table-nav {
    display: flex;
    justify-content: space-between;

    .ant-form-item {
        margin-bottom: 1rem;
    }

    .ant-form-item-control {
        align-self: center;
    }

    .print-action-btn {
        display: flex;
        gap: 10px;
        margin-bottom: 1rem;
    }

}

.tag-count {
    &::after {
        content: "*";
        display: inline
    }
}