.btn-wrapper {
  button {
    background: $blue-01;
    border: none;
    cursor: pointer;

    span {
      color: $white;
    }

    &:hover {
      background: $blue-01 !important;
    }

    &:disabled,
    &:disabled:hover {
      opacity: 0.7;
      background: $blue-01 !important;
    }
    &:active,
    &:focus {
      background: $blue-01 !important;
    }
  }
  .ant-btn-dangerous {
    color: $red;
    border-color: $red;
    background: $red;
    &:hover {
      background: $red !important;
    }
    &:disabled,
    &:disabled:hover {
      opacity: 0.7 !important;
      background: $red !important;
    }
    &:active,
    &:focus {
      background: $red !important;
    }
  }
}
