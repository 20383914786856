.label-wrapper {
    display: flex;
    gap: 2px;
    font-size: 0.625rem;
    // align-items: center;
    flex-direction: column;

    label {
        color: $white;
        font-size: inherit !important;
    }
}