.frame-pick-wrapper {
    thead {
        .ant-table-selection-column {
            display: flex;
            height: 100% !important;
            max-height: 100% !important;
            align-items: center;

            &::after {
                //content: "Found";
                padding-left: 8px;
                font-size: 10px;
            }
        }
    }
}