.color-tag {
    .ant-tag {
        background: none;
    }
}

.active-tag{
    .ant-tag {
        background-color: rgba(69,165,142,.4);
        border: 1px solid #45a58e;
        color: #231f20;
    }
    
}