@import "variables";

.ant-form-item-control {
  .ant-form-item-control-input {
    min-height: $form-item-height;

    .ant-select {
      & > .ant-select:not(.ant-select-customize-input),
      & > .ant-select-selector {
        @include form-element;
        align-items: center;

        input {
          height: $form-item-height;
        }

        & > .ant-select-selection-overflow {
          & > .ant-select-selection-overflow-item {
            margin-top: -6px;

            .ant-tag {
              @include tag-container;
            }
          }
        }
      }

      & > .ant-select-clear,
      & > .ant-select-arrow {
        right: 7px;
      }

      &.ant-select-disabled {
        & > .ant-select-selector {
          color: $form-text-disabled;
        }
      }
    }

    input,
    .ant-picker,
    .ant-input-affix-wrapper {
      @include form-element;

      .ant-picker-suffix {
        color: $blue-01;
      }
    }

    .ant-input-affix-wrapper {
      padding: 0 11px;

      input {
        height: 22px;
      }
    }

    .ant-picker {
      width: 100%;

      .ant-picker-input {
        input {
          padding-left: 0;
        }
      }
    }

    .ant-input[disabled] {
      color: $form-text-disabled;
      cursor: text;
    }
  }

  .ant-form-item-explain {
    font-size: 10px;
    color: $red;
    min-height: auto;
    height: 10px;
  }
}

.ant-select-dropdown {
  .ant-select-item-option {
    font-size: 12px;
    min-height: 20px;
    line-height: 15px;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $highlight-blue-at-30;
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    .ant-picker-panel {
      font-size: 12px;
    }
  }
}

.ant-divider {
  &.ant-divider-horizontal {
    margin: 0px 0;
  }
}

.ant-menu .ant-menu-item:not(.no-hover) a:hover {
  background: $blue-01 !important;
  color: $white !important;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  top: 10px !important;
  margin-bottom: 20px !important;
  animation: none;
  transition: none !important;
}
.ant-menu-light > .ant-menu .ant-menu-item-active:not(.no-hover) a:hover {
  background: inherit;
  color: inherit;
  outline: none;
  animation: none;
  transition: none !important;
}
.ant-menu-light > .ant-menu .ant-menu-item-selected {
  background: $blue-01 !important;
  color: $white !important;
  animation: none;
  outline: none;
  transition: none !important;
}
.ant-menu-light > .ant-menu .ant-menu-item-active {
  background: $blue-01 !important;
  color: $white !important;
  outline: none;
  animation: none;
  transition: none !important;
}
.ant-menu-overflow-item
  .ant-menu-submenu
  .ant-menu-submenu-horizontal
  .ant-menu-submenu-selected {
  color: $blue-01 !important;
  animation: none;
  outline: none;
  transition: none !important;
}
.label-text {
  @include label-text;
}

.ant-btn-link {
  &.staff-link-text {
    color: black;
    font-size: 14px;
    font-weight: 700;
    padding-top: 0;
    height: $form-item-height;

    &.selected {
      color: $highlight-blue;
    }

    span {
      text-decoration: underline;
    }
  }
}

.profile-menu {
  font-size: 12px;

  .username {
    padding: 10px 15px 0;
  }

  .ant-dropdown-menu-item {
    padding: 0;

    .ant-btn {
      font-size: 12px;
      font-weight: 700;

      span {
        text-decoration: underline;
      }
    }
  }
}

.drawer-panel {
  .ant-drawer-content-wrapper {
    width: 300px;

    .ant-drawer-body {
      padding: 0;
    }
  }
}

.ant-modal .ant-modal-content {
  padding: 0px !important;
  font-size: 14px;
}
.ant-modal .ant-modal-close {
  top: 0px !important;
}
.ant-modal-root > .ant-modal-centered > .ant-modal {
  border-radius: 10px;

  .ant-modal-close {
    .ant-modal-close-x {
      width: 40px;
      height: 32px;
      line-height: 32px;
      color: $white;
      font-weight: 800;
      font-size: 14px;
    }
  }

  .ant-modal-header {
    padding: 4px 20px 4px !important;
    background: $blue-01;
    border-bottom: 3px solid $blue-01;

    .ant-modal-title {
      font-size: 12px;
      font-weight: 800;
      color: $white;
    }
  }

  .ant-modal-body {
    padding: 20px;
    font-size: 16px !important;
  }

  .ant-modal-footer {
    padding: 4px 20px 2px;

    .ant-btn {
      font-size: 12px;
      font-weight: 800;
      background-color: $blue-01;
      color: $white;
      border: $blue-01;
      /* Adjusting button colors based on variant */
      &.ant-btn-primary {
        background-color: $blue-01; /* Primary color */
        color: $white;
        border-color: $blue-01;
      }

      &.ant-btn-default {
        background-color: $red; /* Default button color */
        color: $white; /* Default button text color */
        border-color: $red; /* Default button border */
      }
    }
  }
}

.tag-container > .ant-tag {
  @include tag-container;
}

.stuff-item-container {
  width: 100%;

  .stuff-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &:hover {
      background-color: $highlight-light;
    }

    .unassign-action {
      height: 24px;
      display: flex;
      align-items: center;
      padding: 0;

      &:disabled {
        color: $gray-02;

        img {
          filter: opacity(0.25) grayscale(100%);
        }
      }
    }
  }
}

.add-picker-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0;

  > div {
    width: 100%;
  }

  &-zip,
  &-city,
  &-state {
    width: 30% !important;
    margin-right: 8px !important;
  }

  .ant-form-item {
    margin-bottom: 0;

    &.checkbox-item {
      width: 85px;
      display: inline-block;
    }

    .ant-form-item {
      &-label {
        padding-bottom: 0;

        > label {
          @include label-text;
          display: flex;
          align-items: flex-end;
          padding-top: 6px;
          font-weight: 700;
          font-size: 11px;
          color: #1b61a7;
        }
      }
    }
  }

  .drawer-panel {
    .ant-drawer-content-wrapper {
      width: 300px;

      .ant-drawer-body {
        padding: 0;
      }
    }
  }

  .dc-drawer-panel {
    .ant-drawer-content-wrapper {
      width: 300px;

      .ant-drawer-header {
        background-color: $gray-02;
        padding: 14px 20px;

        .ant-drawer-header-title {
          flex-direction: row-reverse;

          .ant-drawer-close {
            img {
              height: 14px;
            }
          }
          .ant-drawer-title {
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            color: #1b61a7;
          }
        }
      }

      .ant-drawer-body {
        padding: 0;
        .header {
          height: 50px;
          padding: 0 20px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $gray-02;
          color: $blue-01;

          &__text {
            font-weight: 600;
          }

          &__close {
            font-weight: 700;
            text-decoration: none;
            font-size: 22px;
          }
        }

        .subheader {
          margin: 20px;
          border-bottom: 2px solid $gray-02;
          padding-bottom: 25px;
          display: flex;
          justify-content: space-between;

          &__text {
            display: flex;
            font-weight: 700;
          }

          &__id {
            margin-left: 20px;
          }

          &__office {
            margin-left: 20px;
          }

          &__office-name {
            margin-left: 20px;
          }

          &__date {
            font-size: 11px;
            font-weight: bold;
          }
        }

        .week {
          position: relative;
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 30px;
          padding-bottom: 30px;

          &__pattern-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &__week-number {
            margin-left: 20px;
            font-size: 11px;
            font-weight: bold;
          }

          &__wrapper {
            display: flex;
            margin-top: 20px;
          }

          &__column-title {
            height: 18px;
            font-weight: bold;
          }

          &__column-value {
            height: 18px;

            &_grayed-out {
              color: #a9a9a9;
            }
          }

          &__day-column {
            width: 95px;
          }

          &__date-column {
            width: 85px;
          }

          &__time-column {
            white-space: nowrap;
            background-color: pink;
          }

          &__assign-btn-wrap {
            padding-bottom: 15px;
            margin-top: 15px;
            margin-bottom: 30px;
            border-bottom: 2px solid $gray-02;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          &__month-assign-btn-wrap {
            padding-bottom: 15px;
            margin-top: 15px;
            margin-bottom: 30px;
            // border-bottom: 2px solid $gray-02;
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            align-items: center;
          }
        }

        .week-grid {
          margin-top: 20px;

          th.ant-table-cell {
            font-size: 14px;
            font-weight: bold;
            text-transform: capitalize;
            padding: 0 0 0 3px;
            text-align: left;
            background-color: white;
          }

          td.ant-table-cell {
            height: unset;
            font-size: 14px;
            padding: 0 0 0 3px;
            background-color: white;

            .grayed-out {
              color: #a9a9a9;
            }
            .range0-template-text {
              width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .assigned-text {
    color: $blue-01;
    font-weight: 700;
    font-size: 14px;
    margin-top: 5px;
  }
}

.assortment-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0;

  > div {
    width: 50%;
  }

  &-zip,
  &-city,
  &-state {
    width: 30% !important;
    margin-right: 8px !important;
  }

  .ant-form-item {
    margin-bottom: 0;

    &.checkbox-item {
      width: 85px;
      display: inline-block;
    }

    .ant-form-item {
      &-label {
        padding-bottom: 0;

        > label {
          @include label-text;
          display: flex;
          align-items: flex-end;
          padding-top: 6px;
          font-weight: 700;
          font-size: 11px;
          color: #1b61a7;
        }
      }
    }
  }

  .drawer-panel {
    .ant-drawer-content-wrapper {
      width: 300px;

      .ant-drawer-body {
        padding: 0;
      }
    }
  }

  .dc-drawer-panel {
    .ant-drawer-content-wrapper {
      width: 300px;

      .ant-drawer-header {
        background-color: $gray-02;
        padding: 14px 20px;

        .ant-drawer-header-title {
          flex-direction: row-reverse;

          .ant-drawer-close {
            img {
              height: 14px;
            }
          }
          .ant-drawer-title {
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            color: #1b61a7;
          }
        }
      }

      .ant-drawer-body {
        padding: 0;
        .header {
          height: 50px;
          padding: 0 20px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $gray-02;
          color: $blue-01;

          &__text {
            font-weight: 600;
          }

          &__close {
            font-weight: 700;
            text-decoration: none;
            font-size: 22px;
          }
        }

        .subheader {
          margin: 20px;
          border-bottom: 2px solid $gray-02;
          padding-bottom: 25px;
          display: flex;
          justify-content: space-between;

          &__text {
            display: flex;
            font-weight: 700;
          }

          &__id {
            margin-left: 20px;
          }

          &__office {
            margin-left: 20px;
          }

          &__office-name {
            margin-left: 20px;
          }

          &__date {
            font-size: 11px;
            font-weight: bold;
          }
        }

        .week {
          position: relative;
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 30px;
          padding-bottom: 30px;

          &__pattern-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &__week-number {
            margin-left: 20px;
            font-size: 11px;
            font-weight: bold;
          }

          &__wrapper {
            display: flex;
            margin-top: 20px;
          }

          &__column-title {
            height: 18px;
            font-weight: bold;
          }

          &__column-value {
            height: 18px;

            &_grayed-out {
              color: #a9a9a9;
            }
          }

          &__day-column {
            width: 95px;
          }

          &__date-column {
            width: 85px;
          }

          &__time-column {
            white-space: nowrap;
            background-color: pink;
          }

          &__assign-btn-wrap {
            padding-bottom: 15px;
            margin-top: 15px;
            margin-bottom: 30px;
            border-bottom: 2px solid $gray-02;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          &__month-assign-btn-wrap {
            padding-bottom: 15px;
            margin-top: 15px;
            margin-bottom: 30px;
            // border-bottom: 2px solid $gray-02;
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            align-items: center;
          }
        }

        .week-grid {
          margin-top: 20px;

          th.ant-table-cell {
            font-size: 14px;
            font-weight: bold;
            text-transform: capitalize;
            padding: 0 0 0 3px;
            text-align: left;
            background-color: white;
          }

          td.ant-table-cell {
            height: unset;
            font-size: 14px;
            padding: 0 0 0 3px;
            background-color: white;

            .grayed-out {
              color: #a9a9a9;
            }
            .range0-template-text {
              width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .assigned-text {
    color: $blue-01;
    font-weight: 700;
    font-size: 14px;
    margin-top: 5px;
  }
}

.search-field-size {
  width: 150;
}

.add-form {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0;

  > div {
    width: 50%;
  }

  .ant-form-item {
    margin-bottom: 0;
    margin-right: 20px;
    margin-left: 20px;

    // &.checkbox-item {
    //   width: 85px;
    //   display: inline-block;
    // }

    .ant-form-item {
      &-label {
        padding-bottom: 0;

        > label {
          // @include label-text;
          display: flex;
          align-items: flex-end;
          padding-top: 6px;
          font-weight: 700;
          font-size: 11px;
          // color: #1b61a7;
        }
      }
    }
  }
  // .assigned-text {
  //   color: $blue-01;
  //   font-weight: 700;
  //   font-size: 14px;
  //   margin-top: 5px;
  // }
  .fed-ex-search {
    position: absolute;
    bottom: 0;
  }
}

.ant-menu-overflow-item .ant-menu-submenu .ant-menu-submenu-horizontal {
  color: $white !important;
}

.ant-menu-title-content {
  display: none;
}

.ant-form-item-explain-error {
  font-size: 10px;
}
